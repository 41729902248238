import { defineStore } from 'pinia'
import { jwtDecode } from "jwt-decode";
import type { components } from '~/types/schema';
import * as storage from '~/stores/localStorage'
import moment from 'moment'
import type { ProjectDtoRes, MessageThreadDtoRes } from '@/types/api';

const key = 'useProjectStore'

export const useProjectStore = defineStore('useProjectStore', () => {


  const _data = ref<Array<components["schemas"]["ProjectDtoRes"]>>([])
  const _loading = ref<boolean>(false)
  const dateFetched = ref<Date>(null)

  const data = computed<Array<components["schemas"]["ProjectDtoRes"]>>(() => _data.value)
  const ready = computed<boolean>(() => dateFetched ? true : false)
  const loading = computed<boolean>(() => _loading.value)
  const timestamp = computed<string>(() => dateFetched.value.toISOString())
  
  const init = () => {
    const data = load(key)
  
    if(data){
      _data.value = data.payload 
      dateFetched.value = new Date(data.timestamp)
      get()
      return true
    }
      
    return get() 
  }
  
  const get = async(id?: number) => {

    try{
      
      _loading.value = true
      if(typeof id === 'number'){
        const { data, error } = await useApi('/project/{id}', {path: { id } })
      
        if(error.value){
          _loading.value = false
          throw error
        }
        
        const index = _data.value.findIndex(el => el.id == id)
        
        if(index != -1){
          _data.value[index] = data.value
          _data.value[index].latestActivityDate = new Date().toISOString()
        }
          
        
        else 
          _data.value.push(data.value)
      
        _data.value = _data.value.sort(sortByDate)

      } else {
        // const { data, error } = await useApi("/project/logged/user")
        const {data, error} = await useFapi('/v1/projects/')//useApi('/project/logged/user')

        if(error.value){
          _loading.value = false
          throw error
        }
        // @ts-ignore
        _data.value = data.value.sort(sortByDate)
      }
      

      dateFetched.value = new Date()
      _loading.value = false
      
      storage.set(key, _data.value)
      
      return true
      
    }
    catch(err){
      throw err
    }
  }
  
  const set = (id: number, data: object = {}) => {
    const index = _data.value.findIndex(el => el.id == id)
    _data.value[index] = {
      ..._data.value[index],
      latestActivityDate: new Date().toISOString(),
      ...data
    }
    
    _data.value = _data.value.sort(sortByDate)
  }
  const sortByDate = (a: ProjectDtoRes | MessageThreadDtoRes, b: ProjectDtoRes | MessageThreadDtoRes) =>{
    return moment(a.latestActivityDate).isAfter(b.latestActivityDate) ? -1 : 1
  }
  
 

  return { data, get, ready, init, timestamp, loading, set }
  
})